import "./App.css";
import { useAppKitTheme } from "@reown/appkit/react";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";

import { ToastContainer } from "react-toastify";
import routes from "./Router";
import Layout from "./layouts";

function App() {
  const { setThemeMode, setThemeVariables } = useAppKitTheme();

  useEffect(() => {
    setThemeMode("light");

    setThemeVariables({
      "--w3m-color-mix": "#9A8700",
      "--w3m-color-mix-strength": 40,
    });
  }, [setThemeMode, setThemeVariables]);
  return (
    <div className="page bg-gradient-to-r from-black via-gray-950 to-[#fdffc4]">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {routes.map((route) => {
            return (
              <Route
                key={route.id}
                path={route.path}
                element={<Layout component={route.element} />}
              />
            );
          })}
        </Routes>

        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
