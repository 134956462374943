import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URLS } from "../../Config/apiUrls";
import axiosInstance1 from "../../Config/axios1";
import { Dialog, Divider } from "@mui/material";
import axiosInstance from "../../Config/axios";
import { toast } from "react-toastify";
import box from "../../images/box.png";
import rewar from "../../images/rewar.png";

export default function Airdrop() {
  const [isLoading, setIsLoading] = useState(false);
  const [projectData, setProjectData] = useState();
  const [item1, setItem1] = useState();
  const [open, setOpen] = React.useState(false);
  const [ledbord, setLedbord] = useState();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [getUserdata, setUserData] = useState();
  const [getTask, setGetTask] = useState();
  const [dilogloder, setDilogloder] = useState(false);

  const getTaskSingale1 = (id) => {
    setDilogloder(true);
    axiosInstance1
      .get(API_URLS.TaskdetailTaskid + `/${id}`)
      .then((res) => {
        setItem1(res.data.data);
        setDilogloder(false);
      })
      .catch((err) => {
        console.log(err);
        setDilogloder(false);
      });
  };
  const handleOpen = (item) => {
    setOpen(true);
    getTaskSingale1(item.id);
  };

  const handleOpenTab = (id) => {
    const url = `${id}`;
    window.open(url);
  };

  const openWindow = (id) => {
    const width = 1000;
    const height = 800;
    const leftPosition = (window.screen.width - width) / 2;
    const topPosition = (window.screen.height - height) / 2;
    const windowFeatures = `width=${width},height=${height},left=${leftPosition},top=${topPosition},resizable=yes,scrollbars=yes,status=yes`;

    const newWindow = window.open(`${id}`, "_blank", windowFeatures);
    if (newWindow) {
      newWindow.focus();
    } else {
      alert("Popup blocked! Please allow popups for this site.");
    }
  };

  const handleClose = () => setOpen(false);
  const getProjectData = () => {
    setIsLoading(true);
    axiosInstance1
      .get(API_URLS.projectlist)
      .then((res) => {
        setProjectData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getLedbord = () => {
    axiosInstance1
      .get(API_URLS.getTopLeader)
      .then((res) => {
        setLedbord(res.data.topleaderData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDashboard = () => {
    setIsLoading(true);
    axiosInstance
      .get(API_URLS.getDashboard)
      .then((res) => {
        setUserData(res.data.userdata);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDashboard();
  }, []);
  const getTaskSingale = () => {
    axiosInstance1
      .get(API_URLS.Tasklistbyproject + `/${projectData?.id}`)
      .then((res) => {
        setGetTask(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const TaskFunction = (id) => {
    axiosInstance
      .post(API_URLS.UserSocialAction, { taskid: id })
      .then((res) => {
        toast.success(res.data.message);
        if (res.data.actionUrl) {
          openWindow(res.data.actionUrl);
          getTaskSingale1(id);
          // window.open(`${res.data.actionUrl}`, "_blank");
        }
        getTaskSingale();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (projectData?.id) {
      getTaskSingale();
    }
  }, [projectData?.id]);
  useEffect(() => {
    getProjectData();
    getLedbord();
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [!token]);

  // console.log(item1);

  return (
    <div>
      {isLoading ? (
        <div className="loader-mask">
          <div className="loader">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="min-h-screen bg-black ">
          {/* Header Section */}
          <div className="relative bg-blue-500 h-[60vh]">
            <div className="absolute inset-0">
              <img
                src={projectData?.projectBannerPath}
                alt="Background"
                className="w-full h-full object-cover"
              />
            </div>

            <div className="absolute inset-0 lg:max-w-7xl mx-auto px-10  flex justify-between mt-[25%] lg:bg-gradient-to-b from-black via-gray-950 to-[#fdffc4]  items-center my-2 rounded-md !bg-opacity-80">
              <div className="lg:block hidden">
                <p className="text-customOrange text-2xl font-bold">
                  {projectData?.projectTitle}
                </p>
                <p className="text-black font-semibold">
                  {projectData?.projectDescription}
                </p>
              </div>
              <div className="flex flex-col items-center ">
                <p className="text-customOrange  font-bold text-2xl lg:block hidden">
                  BDT POINT
                </p>
                <div className="text-black flex items-center gap-2 font-bold">
                  <p className="lg:block hidden">
                    {getUserdata?.totalpoint.split(".")[0]}{" "}
                  </p>
                  <img
                    src="https://snagsolutions.io/cdn-cgi/image/width=40/https://snag-assets.s3.us-west-2.amazonaws.com/organizations/8f48e0f1-f648-4b0e-99be-3a3c25597a97/websites/d88e4c28-d8cc-45ff-8cff-1180cdc1e87c/images/Satoshi-Currency.png"
                    alt=""
                    className="w-4 h-4 lg:block hidden"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex max-w-full bg-gradient-to-r from-black via-gray-950 to-[#fdffc4] ">
            <div className="max-w-7xl mx-auto lg:px-6 py-10 page ">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 ">
                <div className="md:col-span-2  rounded-lg  p-6">
                  <h2 className="text-xl font-bold text-customOrange mb-4">
                    BDT coin Ecosystem Hub
                  </h2>
                  <p className="text-customOrange mb-6">BDT coin Network</p>

                  <div className="space-y-4 lg:h-[72vh] overflow-auto">
                    {getTask
                      ?.filter((item) => item.taskComplete === false)
                      .map((item) => {
                        return (
                          <div className="box lg:flex items-center justify-between p-4  border border-customOrange rounded-lg bg-black bg-opacity-70 shadow">
                            <div className="flex items-center">
                              <div className="lg:block hidden">
                                <div class="ribbon-wrapper">
                                  <div class="ribbon">
                                    {item.taskpoint.split(".")[0]}{" "}
                                    <span>Bdt Point</span>
                                  </div>
                                </div>
                              </div>

                              <div className="flex flex-col lg:px-20">
                                <div className="flex gap-2 items-center ">
                                  <img
                                    src={item.tasklogoPath}
                                    alt=""
                                    className="h-10 w-10"
                                  />
                                  <p className="text-customOrange font-semibold">
                                    {item.SubcatgeoryName}
                                  </p>
                                </div>

                                <p className="text-gray-200">
                                  {item.taskTitle}
                                </p>
                                <p className="bg-yellow-100 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 my-2">
                                  {item.taskdescribtion}
                                </p>
                              </div>
                            </div>
                            <div className="flex justify-between items-center lg:my-0 my-2">
                              <div className=" gap-2 flex items-center justify-center bg-orange-100 text-orange-600 font-bold rounded-full lg:hidden p-2 w-[30%] mr-4">
                                {item.taskpoint.split(".")[0]}
                                <img
                                  src="https://snagsolutions.io/cdn-cgi/image/width=40/https://snag-assets.s3.us-west-2.amazonaws.com/organizations/8f48e0f1-f648-4b0e-99be-3a3c25597a97/websites/d88e4c28-d8cc-45ff-8cff-1180cdc1e87c/images/Satoshi-Currency.png"
                                  alt=""
                                  className="w-4 h-4"
                                />
                              </div>
                              <button
                                onClick={() => handleOpen(item)}
                                className="px-4 py-2 text-sm  text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                              >
                                Go to {item.SubSubcatgeoryName}
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                {/* Right Section - Leaderboard */}
                <div className=" rounded-lg  p-6">
                  <h2 className="text-xl font-bold text-customOrange mb-4">
                    Leaderboard
                  </h2>
                  <div className="flex flex-col">
                    {ledbord?.map((i, ind) => {
                      return (
                        <div>
                          <div className="flex w-full justify-between text-customOrange items-center my-3">
                            <div className="flex gap-10 text-center  items-center">
                              {ind + 1 === 1 ? (
                                <img
                                  src="https://snagsolutions.io/cdn-cgi/image/width=32/https://admin.snagsolutions.io/icons/figma/first-award.png"
                                  alt=""
                                  className="h-8 w-8"
                                />
                              ) : ind + 1 === 2 ? (
                                <img
                                  src="https://snagsolutions.io/cdn-cgi/image/width=32/https://admin.snagsolutions.io/icons/figma/second-award.png"
                                  alt=""
                                  className="h-8 w-8"
                                />
                              ) : ind + 1 === 3 ? (
                                <img
                                  src="https://snagsolutions.io/cdn-cgi/image/width=32/https://admin.snagsolutions.io/icons/figma/third-award.png"
                                  alt=""
                                  className="h-8 w-8"
                                />
                              ) : (
                                <p className="text-center h-8 w-8">{ind + 1}</p>
                              )}

                              <div className="flex gap-5 items-center">
                                <img
                                  src="https://snag-assets.s3.us-west-2.amazonaws.com/users/5df86a30-5a92-4331-a686-0e2df3fb2afc/profiles/copy-of-yellow-minimalist-round-shaped-cafe-logo.jpg"
                                  alt=""
                                  className="h-8 w-8 rounded-full"
                                />
                                <div className="flex flex-col text-sm">
                                  <p className="text-customOrange font-semibold">
                                    {i.fullname === null
                                      ? `${i.user_id?.slice(
                                          0,
                                          4
                                        )}...${i.user_id?.slice(-6)}`
                                      : i.fullname}
                                  </p>
                                  <p className="text-gray-400">
                                    {" "}
                                    {i.address?.slice(0, 4)}...
                                    {i.address?.slice(-6)}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center gap-3">
                              <p className="text-black font-bold">
                                {i.totalTask}
                              </p>
                              <img
                                src="https://snagsolutions.io/cdn-cgi/image/width=40/https://snag-assets.s3.us-west-2.amazonaws.com/organizations/8f48e0f1-f648-4b0e-99be-3a3c25597a97/websites/d88e4c28-d8cc-45ff-8cff-1180cdc1e87c/images/Satoshi-Currency.png"
                                alt=""
                                className="w-4 h-4"
                              />
                            </div>
                          </div>
                          <Divider
                            className="theme_border"
                            color="customOrange"
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Dialog
            open={open}
            onClose={() => handleClose()}
            PaperProps={{
              sx: {
                bgcolor: "#ffa800", // Optional: to add a fallback color
                color: "white",
                borderRadius: "16px",
                boxShadow: "none",
                backgroundImage: `url(${box})`, // Add the image here
                backgroundSize: "cover", // Ensures the image covers the entire background
                backgroundRepeat: "no-repeat", // Prevents image repetition
                backgroundPosition: "center", // Centers the image
                border: "1px solid yellow",
              },
              className: `lg:w-[50%] lg:h-[50%] lg:px-5 py-1 px-2`,
            }}
          >
            <div>
              {dilogloder ? (
                <div className="loader-mask">
                  <div className="loader">
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) : (
                <div className="flex  flex-col lg:px-[10%] py-2 lg:py-5 gap-10">
                  <div className="flex flex-col ">
                    <div className="lg:flex items-center w-full justify-between ">
                      <div className="lg:flex items-center ">
                        <img
                          src={item1?.tasklogoPath}
                          alt=""
                          className="object-contain w-full h-20 lg:w-20"
                        />
                        <span>
                          <p className="text-2xl text-customOrange font-bold">
                            {item1?.SubcatgeoryName}
                          </p>
                          <p className="text-gray-200">{item1?.taskTitle}</p>
                        </span>
                      </div>
                      <div className="flex flex-col items-center">
                        <div
                          className=" h-20 w-20 shadow-none bg-cover bg-no-repeat bg-center flex items-center justify-center "
                          style={{ backgroundImage: `url(${rewar})` }}
                        >
                          <p className="font-bold  mb-[20%] text-black ">
                            {item1?.taskpoint?.split(".")[0]} BDT
                          </p>
                        </div>
                        {/* <p className="font-bold text-customOrange">Point</p> */}
                      </div>
                    </div>

                    <p className="bg-yellow-100 text-xs font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-yellow-900 my-2">
                      {item1?.taskdescribtion}
                    </p>
                    <div className=" flex flex-col">
                      <p className="text-xl text-customOrange font-bold my-2">
                        Content:
                      </p>
                      <div className=" flex p-2 h-36 overflow-y-auto w-full border border-customOrange rounded-xl">
                        <p className="text-customOrange font-bold">
                          {item1?.taskContaint}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center  justify-center ">
                    {item1?.taskComplete ? (
                      <button
                        // onClick={() => TaskFunction(item1?.id)}
                        className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                      >
                        Completed
                      </button>
                    ) : (
                      <>
                        {item1?.subcategory_id === "1" &&
                          (getUserdata?.twitterLinkstatus === "0" ? (
                            <button
                              onClick={() =>
                                (window.location.href = `https://poolliquidity.aquaeal.in/ApiV3/TwitterAuth/login?UserToken=${token}`)
                              }
                              className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                            >
                              Go to {item1?.SubSubcatgeoryName}
                            </button>
                          ) : (
                            <button
                              onClick={() => TaskFunction(item1?.id)}
                              className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                            >
                              Go to {item1?.SubSubcatgeoryName}
                            </button>
                          ))}
                        {/* you tube */}
                        {item1?.subcategory_id === "2" &&
                          (getUserdata?.youtubelinkStatus === "0" ? (
                            <button
                              onClick={() =>
                                (window.location.href = `https://poolliquidity.aquaeal.in/ApiV3/SocialLoginAuth/Youtube?UserToken=${token}`)
                              }
                              className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                            >
                              Go to {item1?.SubSubcatgeoryName}
                            </button>
                          ) : (
                            <button
                              onClick={() => TaskFunction(item1?.id)}
                              className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                            >
                              Go to {item1?.SubSubcatgeoryName}
                            </button>
                          ))}
                        {/* teleram */}
                        {item1?.subcategory_id === "3" &&
                          (!item1?.taskComplete ? (
                            getUserdata?.TelegramLinkStatus === "0" ? (
                              <button
                                onClick={() => navigate("/profile")}
                                className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                              >
                                Go to {item1?.SubSubcatgeoryName}
                              </button>
                            ) : (
                              <div className="flex gap-5 w-full">
                                <button
                                  onClick={() =>
                                    openWindow(
                                      `https://t.me/${item1.taskContaint}`
                                    )
                                  }
                                  className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                                >
                                  Go to {item1?.SubSubcatgeoryName}
                                </button>
                                <button
                                  onClick={() => TaskFunction(item1?.id)}
                                  className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                                >
                                  Verify
                                </button>
                              </div>
                            )
                          ) : (
                            <button
                              onClick={() => TaskFunction(item1?.id)}
                              className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                            >
                              Completed
                            </button>
                          ))}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Dialog>
        </div>
      )}
    </div>
  );
}
