import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import LoginFunction from "../../utils/LoginFunction";
import logo from "../../images/favicon.png";
import { Divider } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Dialog from "@mui/material/Dialog";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { disconnectMutationOptions } from "wagmi/query";
import TaskIcon from "@mui/icons-material/Task";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/apiUrls";
import box from "../../images/box.png";
import axiosInstance1 from "../../Config/axios1";
import { toast } from "react-toastify";
import Person4Icon from "@mui/icons-material/Person4";

export default function Sidebar() {
  const navgate = useNavigate();
  const { address, isConnected, isDisconnected } = useAccount();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [getUserdata, setUserData] = useState();
  const [getTask, setGetTask] = useState();
  const token = localStorage.getItem("token");
  const dailyIncompleteTasks = getTask?.filter((item) => {
    return item.taskComplete === false && item.taskType === "daily";
  });

  const getDashboard = () => {
    axiosInstance
      .get(API_URLS.getDashboard)
      .then((res) => {
        setUserData(res.data.userdata);
      })
      .catch((err) => {
        console.log(err);
        logout();
      });
  };

  useEffect(() => {
    if (dailyIncompleteTasks > 0) {
      handleOpen();
    }
  }, [dailyIncompleteTasks]);

  const openWindow = (id) => {
    const width = 1000;
    const height = 800;
    const leftPosition = (window.screen.width - width) / 2;
    const topPosition = (window.screen.height - height) / 2;
    const windowFeatures = `width=${width},height=${height},left=${leftPosition},top=${topPosition},resizable=yes,scrollbars=yes,status=yes`;

    const newWindow = window.open(`${id}`, "_blank", windowFeatures);
    if (newWindow) {
      newWindow.focus();
    } else {
      alert("Popup blocked! Please allow popups for this site.");
    }
  };
  const TaskFunction = (id) => {
    axiosInstance
      .post(API_URLS.UserSocialAction, { taskid: id })
      .then((res) => {
        toast.success(res.data.message);
        if (res.data.actionUrl) {
          openWindow(res.data.actionUrl);
          // getTaskSingale1(id);
          // window.open(`${res.data.actionUrl}`, "_blank");
        }
        getTaskSingale();
      })
      .catch((err) => {
        console.log(err);
        // setIsLoading(false);
      });
  };
  const getTaskSingale = () => {
    axiosInstance1
      .get(API_URLS.Tasklistbyproject + `/1`)
      .then((res) => {
        setGetTask(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (open) {
      getTaskSingale();
    }
  }, [open]);

  useEffect(() => {
    getDashboard(); // Call once on mount
    const interval = setInterval(() => {
      getDashboard();
    }, 10000); // Call every 10 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const logout = () => {
    disconnectMutationOptions();
    localStorage.removeItem("token");
    navgate("/");
  };
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev); // Toggle menu visibility
  };
  const toggleMenu1 = () => {
    setIsOpen(!isOpen);
  };

  return (
    // <div className="page w-full px-5 ">
    //   <div className="flex flex-col w-ful items-center">
    //     <p className="p-6 w-full border text-center bg-customOrange  rounded-3xl text-white font-bold my-5">
    //       <b className="pr-2">Address:</b>
    //       {address?.slice(0, 4)}...
    //       {address?.slice(-6)}
    //     </p>
    //     <p className="p-3 text-3xl flex flex-col w-full border text-center bg-customOrange rounded-3xl text-white font-bold my-5">
    //       <b className="text-xl">Total Point</b>
    //       20
    //     </p>
    //     <p className="p-3 text-3xl flex flex-col w-full border text-center bg-customOrange rounded-3xl text-white font-bold my-5">
    //       <b className="text-xl">Completed Tasks</b>
    //       20
    //     </p>

    //     <div className="flex flex-col">
    //       <p className="text-3xl font-bold">Social media accounts</p>
    //       <div className="p-3 flex  w-full justify-between border items-center bg-customOrange rounded-3xl text-white font-bold my-3 ">
    //         <div className="flex items-center gap-2">
    //           <img
    //             src="https://static.vecteezy.com/system/resources/thumbnails/027/395/710/small/twitter-brand-new-logo-3-d-with-new-x-shaped-graphic-of-the-world-s-most-popular-social-media-free-png.png"
    //             alt=""
    //             className="h-10 w-10"
    //           />
    //           <b className="text-2xl">Twitter</b>
    //         </div>

    //         <p className="border flex items-end px-2 rounded-lg bg-black cursor-pointer">
    //           Link
    //         </p>
    //       </div>
    //       <div className="p-3 flex  w-full justify-between border items-center bg-customOrange rounded-3xl text-white font-bold my-3 ">
    //         <div className="flex items-center gap-2">
    //           <img
    //             src="https://cdn-icons-png.flaticon.com/512/2111/2111646.png"
    //             alt=""
    //             className="h-10 w-10"
    //           />
    //           <b className="text-2xl">Teligram</b>
    //         </div>

    //         <p className="border flex items-end px-2 rounded-lg bg-black cursor-pointer">
    //           Link
    //         </p>
    //       </div>
    //       <div className="p-3 flex  w-full justify-between border items-center bg-customOrange rounded-3xl text-white font-bold my-3 ">
    //         <div className="flex items-center gap-2">
    //           <img
    //             src="https://cdn-icons-png.flaticon.com/128/5968/5968852.png"
    //             alt=""
    //             className="h-10 w-10"
    //           />
    //           <b className="text-2xl">You Tube</b>
    //         </div>

    //         <p className="border flex items-end px-2 rounded-lg bg-black cursor-pointer">
    //           Link
    //         </p>
    //       </div>
    //       <div className="p-3 flex  w-full justify-between border items-center bg-customOrange rounded-3xl text-white font-bold my-3 ">
    //         <div className="flex items-center gap-2">
    //           <img
    //             src="https://cdn-icons-png.flaticon.com/512/3670/3670124.png"
    //             alt=""
    //             className="h-10 w-10"
    //           />
    //           <b className="text-2xl">Facebook</b>
    //         </div>

    //         <p className="border flex items-end px-2 rounded-lg bg-black cursor-pointer">
    //           Link
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <header className="bg-customOrange text-white lg:w-[80%] z-10  rounded-xl shadow-2xl w-[90%] fixed top-2 ">
      <nav className="py-5 px-5 flex items-center justify-between">
        <div className="flex font-semibold cursor-pointer items-center gap-4 ">
          <p onClick={() => navgate("/")} className="">
            <img src={logo} alt="Logo" className=" h-10" />
          </p>
          <span
            onClick={() => navgate("/")}
            className="flex hover:underline items-center cursor-pointer"
          >
            <HomeIcon className="text-black !text-md !hover:underline " />
            <p className="text-black text-md lg:block hidden">Home</p>
          </span>

          <Divider orientation="vertical" variant="middle" flexItem />
          <XIcon className="text-black !text-md !hover:underline cursor-pointer " />
          <TelegramIcon className="text-black !text-md !hover:underline cursor-pointer " />
          <YouTubeIcon className="text-black !text-md !hover:underline cursor-pointer " />
          {/* <FacebookIcon className="text-black !text-md " /> */}
        </div>
        {/* <Taskdilay open={open} setOpen={setOpen} /> */}

        <div className="hidden md:flex space-x-6">
          <div
            onClick={() => handleOpen()}
            className="flex items-center bg-black w-42 gap-2 px-2 rounded-xl cursor-pointer"
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/9425/9425012.png"
              alt=""
              className="h-8 w-8"
            />
            <p>Daily task</p>
          </div>
          <div className="flex items-center border rounded-full   ">
            <p className="text-black flex items-center w-20 gap-2 px-5">
              <p className="font-bold">
                {getUserdata?.totalpoint.split(".")[0]}{" "}
              </p>
              <img
                src="https://snagsolutions.io/cdn-cgi/image/width=40/https://snag-assets.s3.us-west-2.amazonaws.com/organizations/8f48e0f1-f648-4b0e-99be-3a3c25597a97/websites/d88e4c28-d8cc-45ff-8cff-1180cdc1e87c/images/Satoshi-Currency.png"
                alt=""
                className="w-4 h-4"
              />
            </p>
            <div
              onClick={() => toggleMenu1()}
              className="flex py-2 gap-1 px-3 text-black items-center border-l rounded-full cursor-pointer"
            >
              <span className="p-1 border rounded-full bg-gray-100">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/456/456212.png"
                  alt=""
                  className="h-5 w-5"
                />
              </span>

              <p className="font-bold">
                {address?.slice(0, 4)}...
                {address?.slice(-6)}
              </p>
              <KeyboardArrowDownIcon />
            </div>
          </div>
          {isOpen && (
            <div className="absolute right-5 mt-[3.5%] w-[20%] bg-customOrange border rounded-lg shadow-lg ">
              <div className="flex gap-2 items-center h-10 text-black py-10 justify-between px-5 hover:bg-[#7e5716] hover:text-white ">
                <div className="flex items-center gap-2">
                  <span className="p-1 border rounded-full bg-gray-100">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/456/456212.png"
                      alt=""
                      className="h-5 w-5"
                    />
                  </span>

                  <p className="font-bold">
                    {address?.slice(0, 4)}...
                    {address?.slice(-6)}
                  </p>
                </div>
                <ArrowRightAltIcon />
              </div>
              <Divider color="black" />
              <div
                onClick={() => {
                  navgate("/profile");
                  toggleMenu1();
                }}
                className="flex items-center justify-between hover:bg-[#7e5716] hover:text-white p-5 text-black cursor-pointer hover:"
              >
                <div className="flex items-center gap-5 ">
                  <Person4Icon className="" />
                  <p className=" font-semibold ">My Profile</p>
                </div>
                <ArrowForwardIosIcon className=" !text-sm" />
              </div>
              <div
                onClick={() => {
                  navgate("/airdrop");
                  toggleMenu1();
                }}
                className="flex items-center justify-between text-black hover:bg-[#7e5716] hover:text-white p-5 cursor-pointer "
              >
                <div className="flex items-center gap-5 ">
                  <TaskIcon className="" />
                  <p className=" font-semibold ">Task</p>
                </div>
                <ArrowForwardIosIcon className=" !text-sm " />
              </div>
              <div
                onClick={() => logout()}
                className="flex items-center justify-between p-5 cursor-pointer hover:bg-[#7e5716] text-black hover:text-white"
              >
                <div className="flex items-center gap-5 ">
                  <LogoutIcon className="" />
                  <p className=" font-semibold ">Disconnect</p>
                </div>
                <ArrowForwardIosIcon className=" !text-sm " />
              </div>
              <Divider color="black" />
              <div className="flex items-center justify-between p-5">
                <div className="flex items-center gap-5">
                  <AccountBalanceWalletIcon className="text-black" />
                  <p className="text-black font-semibold">Point's</p>
                </div>
                <p className="text-black flex items-center gap-2 ">
                  <p className="font-bold">
                    {getUserdata?.totalpoint.split(".")[0]}{" "}
                  </p>
                  <img
                    src="https://snagsolutions.io/cdn-cgi/image/width=40/https://snag-assets.s3.us-west-2.amazonaws.com/organizations/8f48e0f1-f648-4b0e-99be-3a3c25597a97/websites/d88e4c28-d8cc-45ff-8cff-1180cdc1e87c/images/Satoshi-Currency.png"
                    alt=""
                    className="w-4 h-4"
                  />
                </p>
              </div>
            </div>
          )}
        </div>

        <button
          className="md:hidden text-white focus:outline-none"
          id="mobile-menu-toggle"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </nav>

      <div className={`${isMenuOpen ? "block" : "hidden"} `} id="mobile-menu">
        <div className="absolute  mt-[3.5%] w-full bg-customOrange border rounded-lg shadow-lg ">
          <div className="flex gap-2 items-center h-10 text-black py-10 justify-between px-5 cursor-pointer hover:bg-[#7e5716] hover:text-white ">
            <div className="flex items-center gap-2">
              <span className="p-1 border rounded-full bg-gray-100">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/456/456212.png"
                  alt=""
                  className="h-5 w-5"
                />
              </span>

              <p className="font-bold">
                {address?.slice(0, 4)}...
                {address?.slice(-6)}
              </p>
            </div>
            <ArrowRightAltIcon />
          </div>
          <Divider color="black" />
          <div
            onClick={() => {
              navgate("/profile");
              toggleMenu();
            }}
            className="flex items-center justify-between p-5 cursor-pointer text-black hover:bg-[#7e5716] hover:text-white"
          >
            <div className="flex items-center gap-5 cursor-pointer">
              <Person4Icon className="" />
              <p className=" font-semibold">My Profile</p>
            </div>
            <ArrowForwardIosIcon className=" !text-sm" />
          </div>
          <div
            onClick={() => {
              handleOpen();
              toggleMenu();
            }}
            className="flex items-center justify-between p-5 cursor-pointer hover:bg-[#7e5716] text-black hover:text-white"
          >
            <div className="flex items-center gap-5 cursor-pointer">
              <img
                src="https://cdn-icons-png.flaticon.com/512/9425/9425012.png"
                alt=""
                className="h-8 w-8"
              />
              <p className=" font-semibold">Daily task</p>
            </div>
            <ArrowForwardIosIcon className=" !text-sm" />
          </div>
          <div
            onClick={() => {
              navgate("/airdrop");
              toggleMenu();
            }}
            className="flex items-center justify-between p-5 cursor-pointer hover:bg-[#7e5716] text-black hover:text-white"
          >
            <div className="flex items-center gap-5 cursor-pointer">
              <TaskIcon className="" />
              <p className=" font-semibold">Task</p>
            </div>
            <ArrowForwardIosIcon className=" !text-sm" />
          </div>
          <div
            onClick={() => logout()}
            className="flex items-center justify-between p-5 cursor-pointer hover:bg-[#7e5716] text-black hover:text-white"
          >
            <div className="flex items-center gap-5 cursor-pointer">
              <LogoutIcon className="" />
              <p className=" font-semibold">Disconnect</p>
            </div>
            <ArrowForwardIosIcon className="text-black !text-sm" />
          </div>
          <Divider color="black" />
          <div className="flex items-center justify-between p-5">
            <div className="flex items-center gap-5">
              <AccountBalanceWalletIcon className="text-black" />
              <p className="text-black font-semibold">Point's</p>
            </div>
            <p className="text-black flex items-center gap-2 ">
              <p className="font-bold">
                {getUserdata?.totalpoint.split(".")[0]}{" "}
              </p>
              <img
                src="https://snagsolutions.io/cdn-cgi/image/width=40/https://snag-assets.s3.us-west-2.amazonaws.com/organizations/8f48e0f1-f648-4b0e-99be-3a3c25597a97/websites/d88e4c28-d8cc-45ff-8cff-1180cdc1e87c/images/Satoshi-Currency.png"
                alt=""
                className="w-4 h-4"
              />
            </p>
          </div>
        </div>
      </div>
      <div>
        <Dialog
          open={open}
          onClose={() => handleClose()}
          PaperProps={{
            sx: {
              bgcolor: "#ffa800", // Optional: to add a fallback color
              color: "white",
              borderRadius: "16px",
              boxShadow: "none",
              backgroundImage: `url(${box})`, // Add the image here
              backgroundSize: "cover", // Ensures the image covers the entire background
              backgroundRepeat: "no-repeat", // Prevents image repetition
              backgroundPosition: "center", // Centers the image
              border: "1px solid #ffa800",
            },
            className: `lg:w-[100%] lg:h-[60%]  `,
          }}
        >
          <div className="flex  flex-col lg:px-1 py-2 lg:py-5 gap-10 example">
            <div className="md:col-span-2  rounded-lg  p-6">
              <h2 className="text-xl font-bold text-customOrange mb-4">
                Your Taskboard to Go Viral
              </h2>
              <p className="text-customOrange mb-6">
                Your Content Planner for Better Social Media Engagement
              </p>

              {dailyIncompleteTasks?.length > 0 && (
                <div className="space-y-4">
                  {dailyIncompleteTasks?.map((item) => {
                    return (
                      <div className="box lg:flex flex-col  justify-between p-4 lg:px-20 border border-customOrange rounded-lg bg-black shadow">
                        <div className="flex items-center">
                          <div className="lg:block hidden">
                            <div class="ribbon-wrapper">
                              <div class="ribbon">
                                {item.taskpoint.split(".")[0]}{" "}
                                <span>Bdt Point</span>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-col ">
                            <div className="flex items-center">
                              <img
                                src={item.tasklogoPath}
                                alt=""
                                className="h-10 w-10"
                              />
                              <p className="text-customOrange font-semibold">
                                {item.SubcatgeoryName}
                              </p>
                            </div>

                            <p className="text-gray-200">{item.taskTitle}</p>
                            <p className="bg-yellow-100 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 my-2">
                              {item.taskdescribtion}
                            </p>
                          </div>
                        </div>
                        <div className="flex w-full justify-between items-center lg:my-0 my-2">
                          <div className=" gap-2 flex items-center justify-center bg-orange-100 text-orange-600 font-bold rounded-full lg:hidden p-2 mr-4">
                            {item.taskpoint.split(".")[0]}
                            <img
                              src="https://snagsolutions.io/cdn-cgi/image/width=40/https://snag-assets.s3.us-west-2.amazonaws.com/organizations/8f48e0f1-f648-4b0e-99be-3a3c25597a97/websites/d88e4c28-d8cc-45ff-8cff-1180cdc1e87c/images/Satoshi-Currency.png"
                              alt=""
                              className="w-4 h-4"
                            />
                          </div>
                          <div className="flex items-center w-full  justify-center ">
                            {item?.taskComplete ? (
                              <button
                                // onClick={() => TaskFunction(item1?.id)}
                                className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                              >
                                Completed
                              </button>
                            ) : (
                              <>
                                {item?.subcategory_id === "1" &&
                                  (getUserdata?.twitterLinkstatus === "0" ? (
                                    <button
                                      onClick={() =>
                                        (window.location.href = `https://poolliquidity.aquaeal.in/ApiV3/TwitterAuth/login?UserToken=${token}`)
                                      }
                                      className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                                    >
                                      Go to {item?.SubSubcatgeoryName}
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => TaskFunction(item?.id)}
                                      className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                                    >
                                      Go to {item?.SubSubcatgeoryName}
                                    </button>
                                  ))}
                                {/* you tube */}
                                {item?.subcategory_id === "2" &&
                                  (getUserdata?.youtubelinkStatus === "0" ? (
                                    <button
                                      onClick={() =>
                                        (window.location.href = `https://poolliquidity.aquaeal.in/ApiV3/SocialLoginAuth/Youtube?UserToken=${token}`)
                                      }
                                      className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                                    >
                                      Go to {item?.SubSubcatgeoryName}
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => TaskFunction(item?.id)}
                                      className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                                    >
                                      Go to {item?.SubSubcatgeoryName}
                                    </button>
                                  ))}
                                {/* teleram */}
                                {item?.subcategory_id === "3" &&
                                  (!item?.taskComplete ? (
                                    getUserdata?.TelegramLinkStatus === "0" ? (
                                      <button
                                        onClick={() => navgate("/profile")}
                                        className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                                      >
                                        Go to {item?.SubSubcatgeoryName}
                                      </button>
                                    ) : (
                                      <div className="flex gap-5 w-full">
                                        <button
                                          onClick={() =>
                                            openWindow(
                                              `https://t.me/${item.taskContaint}`
                                            )
                                          }
                                          className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                                        >
                                          Go to {item?.SubSubcatgeoryName}
                                        </button>
                                        <button
                                          onClick={() => TaskFunction(item?.id)}
                                          className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                                        >
                                          Verify
                                        </button>
                                      </div>
                                    )
                                  ) : (
                                    <button
                                      onClick={() => TaskFunction(item?.id)}
                                      className="px-4 py-2 text-sm w-full text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                                    >
                                      Completed
                                    </button>
                                  ))}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {dailyIncompleteTasks?.length === 0 && (
              <div className="flex w-full h-full items-center justify-center">
                <h2 className="text-xl font-bold text-customOrange mb-4">
                  NO TASK FOUND
                </h2>
              </div>
            )}
          </div>
        </Dialog>
      </div>
    </header>
  );
}
